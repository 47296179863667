import {
    addBill,
    getDetailBill,
    updateBill,
    getSourceBill,
    getGoodsFinalQty,
    listSourceBill,
    listSourceBillFranchise,
  } from '@/api/purchase/index'
  import { httpStoreListCheckGoods } from "@/api/store/index";
  import { listDept } from '@/api/system/dept'
  import { fcount } from '@/utils'
import { getGoodsStock } from '@/api/store/index'
  
  let purchaseSet = ['110101', '110102', '110103']
  let saleSet = ['120101', '140313', '120102', '120103']
  let storeSet = [
    '107',
    '108',
    '130101',
    '140309',
    '130102',
    '130103',
    '130104',
    '130105',
    '130106',
    '130107',
    '130108',
    '000'
  ]
  
  function isStoreModule(type) {
    return storeSet.includes(type)
  }
  
  function isPurchaseModule(type) {
    return purchaseSet.includes(type)
  }
  function isSaleModule(type) {
    return saleSet.includes(type)
  }
  
  const storeDetailLayout = {
    data() {
      return {
        sourceStatus: false
      }
    },
    watch: {
      'form.partnerName': function (val) {
        if (val && this.$route.query.type === 'Add') {
          // 先判断是否有引入源单数据
          if (
            this.form.details.length &&
            this.form.details.filter(item => item.sourceBillDetailId).length
          ) {
            this.form.details = [{}]
            this.$nextTick(() => {
              this.$refs.form.clearValidate()
            })
          }
        }
      }
    },
    methods: {
      changeBillDetailType(billType, shopType) {
        if (billType === '130101') {
          //仓库盘点单
          this.showStoreSale = true //显示仓库的字段
          this.urlAdd = '/api/system/store/check/save'
          this.urlDetail = '/api/system/store/check/getDetail'
          this.urlUpdate = '/api/system/store/check/update'
          this.urlBillStatus = '/api/system/store/check/updateBillStatus'
          this.qtyLabel = '盘点数' //数量的字段
          this.moneyLabel = '金额' //金额的字段
          this.unitPriceType = 'purPrice' //标识单价取进价/售价  purPrice=进价  salePrice=售价
        } if (billType === '140309') {
          //门店盘点单
          this.showStoreSale = true //显示仓库的字段
          this.urlAdd = '/api/system/shop/bill/check/save'
          this.urlDetail = '/api/system/shop/bill/check/getDetail'
          this.urlUpdate = '/api/system/shop/bill/check/update'
          this.urlBillStatus = '/api/system/shop/bill/check/updateBillStatus'
          this.qtyLabel = '盘点数' //数量的字段
          this.moneyLabel = '金额' //金额的字段
          this.unitPriceType = 'purPrice' //标识单价取进价/售价  purPrice=进价  salePrice=售价
        } if (billType === '170309') {
          //仓库盘点单
          this.showStoreSale = true //显示仓库的字段
          this.urlAdd = '/api/system/decoration/bill/check/save'
          this.urlDetail = '/api/system/decoration/bill/check/getDetail'
          this.urlUpdate = '/api/system/decoration/bill/check/update'
          this.urlBillStatus = '/api/system/decoration/bill/check/updateBillStatus'
          this.qtyLabel = '盘点数' //数量的字段
          this.moneyLabel = '金额' //金额的字段
          this.unitPriceType = 'purPrice' //标识单价取进价/售价  purPrice=进价  salePrice=售价
        } if (billType === '170409') {
          //仓库盘点单
          this.showStoreSale = true //显示仓库的字段
          this.urlAdd = '/api/system/freshly/baked/bill/check/save'
          this.urlDetail = '/api/system/freshly/baked/bill/check/getDetail'
          this.urlUpdate = '/api/system/freshly/baked/bill/check/update'
          this.urlBillStatus = '/api/system/freshly/baked/bill/check/updateBillStatus'
          this.qtyLabel = '盘点数' //数量的字段
          this.moneyLabel = '金额' //金额的字段
          this.unitPriceType = 'purPrice' //标识单价取进价/售价  purPrice=进价  salePrice=售价
        } else if (billType === '130102') {
          //库存调整单
          this.urlAdd = '/api/system/store/bill/adjust/save'
          this.urlDetail = '/api/system/store/bill/adjust/getDetail'
          this.urlUpdate = '/api/system/store/bill/adjust/update'
          this.urlBillStatus = '/api/system/store/bill/adjust/updateBillStatus'
          this.qtyLabel = '调整数量' //数量的字段
          this.moneyLabel = '金额' //金额的字段
          this.showStoreSale = true //显示仓库的字段
          this.unitPriceType = 'purPrice' //标识单价取进价/售价  purPrice=进价  salePrice=售价
        } else if (billType === '130103') {
          //仓库报损单
          this.urlAdd = '/api/system/store/bill/loss/save'
          this.urlDetail = '/api/system/store/bill/loss/getDetail'
          this.urlUpdate = '/api/system/store/bill/loss/update'
          this.urlBillStatus = '/api/system/store/bill/loss/updateBillStatus'
          this.qtyLabel = '报损数量' //数量的字段
          this.moneyLabel = '金额' //金额的字段
          this.showStoreSale = true //显示仓库的字段
          this.unitPriceType = 'purPrice' //标识单价取进价/售价  purPrice=进价  salePrice=售价
        } else if (billType === '130104') {
          //仓库调拨单
          this.urlAdd = '/api/system/store/bill/allot/save'
          this.urlDetail = '/api/system/store/bill/allot/getDetail'
          this.urlUpdate = '/api/system/store/bill/allot/update'
          this.urlBillStatus = '/api/system/store/bill/allot/updateBillStatus'
          this.moneyLabel = '金额' //金额的字段
          this.qtyLabel = '调拨数量' //数量的字段
          this.showStoreSale = true //显示仓库的字段
          this.unitPriceType = 'purPrice' //标识单价取进价/售价  purPrice=进价  salePrice=售价
        } else if (billType === '130105') {
          //仓库调出单
          this.showStoreSale = true //显示仓库的字段
          this.qtyLabel = '调出数量' //数量的字段
          this.moneyLabel = '金额' //金额的字段
          this.unitPriceType = 'priceCasePrice' //标识单价取进价/售价  purPrice=进价  salePrice=售价
          this.urlSourceBill = '/api/system/source/bill/getSourceBillOrder'
          if (shopType == 2) {
            this.urlAdd = '/api/system/franchise/store/bill/out/save'
            this.urlDetail = '/api/system/franchise/store/bill/out/getDetail'
            this.urlUpdate = '/api/system/franchise/store/bill/out/update'
            this.urlBillStatus = '/api/system/franchise/store/bill/out/updateBillStatus'
          } else {
            this.urlAdd = '/api/system/store/bill/out/save'
            this.urlDetail = '/api/system/store/bill/out/getDetail'
            this.urlUpdate = '/api/system/store/bill/out/update'
            this.urlBillStatus = '/api/system/store/bill/out/updateBillStatus'
          }
        } else if (billType === '130106') {
          //仓库调入单
          this.showStoreSale = true //显示仓库的字段
          this.qtyLabel = '调入数量' //数量的字段
          this.moneyLabel = '金额' //金额的字段
          this.unitPriceType = 'purPrice' //标识单价取进价/售价  purPrice=进价  salePrice=售价
          this.urlSourceBill = '/api/system/source/bill/getSourceBillOrder'
          if (shopType == 2) {
            this.urlAdd = '/api/system/franchise/store/bill/in/save'
            this.urlDetail = '/api/system/franchise/store/bill/in/getDetail'
            this.urlUpdate = '/api/system/franchise/store/bill/in/update'
            this.urlBillStatus = '/api/system/franchise/store/bill/in/updateBillStatus'
          } else {
            this.urlAdd = '/api/system/store/bill/in/save'
            this.urlDetail = '/api/system/store/bill/in/getDetail'
            this.urlUpdate = '/api/system/store/bill/in/update'
            this.urlBillStatus = '/api/system/store/bill/in/updateBillStatus'
          }
        } else if (billType === '130107') {
          //仓库盘盈单
          this.showStoreSale = true //显示仓库的字段
          this.urlAdd = '/api/system/store/bill/inventory/surplus/save'
          this.urlDetail = '/api/system/store/bill/inventory/surplus/getDetail'
          this.urlUpdate = '/api/system/store/bill/inventory/surplus/update'
          this.urlBillStatus =
            '/api/system/store/bill/inventory/surplus/updateBillStatus'
          this.qtyLabel = '盘盈数量' //数量的字段
          this.moneyLabel = '盘盈采购金额' //金额的字段
          this.unitPriceType = 'purPrice' //标识单价取进价/售价  purPrice=进价  salePrice=售价
        } else if (billType === '130108') {
          //仓库盘亏单
          this.showStoreSale = true //显示仓库的字段
          this.urlAdd = '/api/system/store/bill/inventory/loss/save'
          this.urlDetail = '/api/system/store/bill/inventory/loss/getDetail'
          this.urlUpdate = '/api/system/store/bill/inventory/loss/update'
          this.urlBillStatus =
            '/api/system/store/bill/inventory/loss/updateBillStatus'
          this.qtyLabel = '盘亏数量' //数量的字段
          this.moneyLabel = '盘亏采购金额' //金额的字段
          this.unitPriceType = 'purPrice' //标识单价取进价/售价  purPrice=进价  salePrice=售价
        } else if (billType === '1000') {
          // 调拨差异单
          this.qtyLabel = '已发货数量' //数量的字段
          this.showStoreSale = true //显示仓库的字段
        }
        this.getBasicData('detail', '', billType, shopType)
        if (!this.billId) return
        if (this.urlDetail) {
          this.getBillDetail(true)
        }
      },
      getBasicData(sign, type) {
        //获取仓库数据
        allStoreList().then(response => {
          this.storeData = response.data
        })
        //获取员工数据
        allEmployeeList().then(response => {
          this.employeeData = response.data
        })
        if (sign === 'detail') {
          //获取部门数据
          listDept({
            pageNum: 1,
            pageSize: 1000
          }).then(response => {
            this.deptData = response.data
          })
        }
      },
      billUpdateProcess() {
        const sourceArr = this.form.details.filter(
          item => item.sourceBillDetailId
        )
        if (sourceArr.length) {
          this.sourceStatus = true
        }
      },
      deptIdProcess(data) {
        /*
        前端统一部门字段回填
        在采购管理-销售管理中
        只有销售订单为：saleDeptId
        只有采购订单为：purDeptId
        其他为 deptId
          */
  
        if (['120101', '140313'].includes(this.billType)) {
          data.deptId = data.saleDeptId
        } else if (this.billType === '110101') {
          data.deptId = data.purDeptId
        }
        return data
      },
      deptIdFormProcess(data) {
        /*
        在采购管理-销售管理中
        只有销售订单为：saleDeptId
        只有采购订单为：purDeptId
        其他为 deptId
          */
        // 处理销售管理-部门id差异处理
        console.log("data.details 222", this.billType, data.details);
        if (['120101', '140313'].includes(this.billType)) {
          data.saleDeptId = data.deptId
        } else if (this.billType === '110101') {
          data.purDeptId = data.deptId
        }
        return data
      },
      openSelectGoods(index) {
        if (['130101', '140309', '170309', '170409'].includes(this.TypeBill)) {
          //需要检验是否选了仓库时进入
          if (!this.form.storeId) {
            return this.$message({
              message: '请先选择仓库',
              type: 'warning'
            })
          }
          this.openTableData(index, true)
          return
        }
        if (storeSet.includes(this.TypeBill)) {
          this.selectGoodsExtraParams = {
            storeId: this.form.storeId
            // isEnableSale: this.isEnableSale
          }
        } else {
          this.selectGoodsExtraParams = {
            partnerId: this.form.partnerId
            // isEnableSale: this.isEnableSale
          }
        }
  
        this.$nextTick(() => {
          this.OpenGoods = true
          this.selectGoods = '选择商品'
        })
      },
      getTableList(newVal, shopType) {
        this.keepStatus = true
        this.changeBillDetailType(newVal, shopType)
        this.TypeBill = newVal
        // 销售出库单明细
        if (newVal === '120101' || newVal === '140313' || newVal === '120102' || newVal === '120103') {
          this.isEnablePurchase = null
          this.isEnableSale = true
        }
        // 采购
        if (newVal === '110101' || newVal === '110102' || newVal === '103') {
          this.isEnablePurchase = true
          this.isEnableSale = null
        }
      },
      allFun(row, key, index) {
        if (row === '') {
          this.watchScroll()
        }
        this.dataFormat(row, key, index)
  
        this.handleEdit(row, key, index)
      },
      dataFormat(row, key, index) {
        let value = Number(row[key]).toFixed(3)
        value = typeof Number(value) === 'number' && !isNaN(Number(value)) ? Number(value).toFixed(3) : undefined
        this.$set(this.form.details[index], key, value)
      },
      dataReCalc(index, key, value) {
        let formatValue = typeof Number(value) === 'number' && !isNaN(Number(value)) ? Number(value).toFixed(3) : undefined
        this.$set(this.form.details[index], key, formatValue)
      },
      dataShowFilter(sourceAction) {
        let needReCalField = ['taxRate']
        let needFormatField = [
          'unitPrice',
          'unitTaxMoney',
          'attachMoney',
          'taxRate'
        ]
        //字段转换并保留n位小数
        for (let i = 0; i < this.form.details.length; i++) {
          for (let key in this.form.details[i]) {
            let price = this.unitPriceType // 区别单价取进价/售价 unitPriceType = purPrice 取进价  unitPriceType = salePrice 取售价
            if (key === price) {
              // 这里需要注意-> 引入源单回填价格需要取 unitPrice, 先不处理余下逻辑【23.10.8】
              if (sourceAction && sourceAction === 'source') {
                this.dataFormat(this.form.details[i], 'unitPrice', i)
              } else {
                this.dataReCalc(i, 'unitPrice', this.form.details[i][key])
              }
            } else if (needFormatField.includes(key)) {
              this.dataFormat(this.form.details[i], key, i)
            }
          }
        }
      },
      tableDataFilterEmpty() {
        this.form.details = this.form.details.filter(
          item =>
            (!(JSON.stringify(item) === '{}') && !(item.goodsId === undefined)) ||
            !(item.goodsId === undefined)
        )
      },
      keyup(name, event, index, bool) {
        if (['130101', '140309', '170309', '170409'].includes(this.TypeBill)) {
          //需要检验是否选了仓库时进入
          if (!this.form.storeId) {
            return this.$message({
              message: '请先选择仓库',
              type: 'warning'
            })
          }
          this.openTableData(index)
          return
        }
        //判断是否输入编码或条码
        if (this.form.details[`${index}`][`${name}`]) {
          listGoods({
            [name]: this.form.details[`${index}`][`${name}`],
            partnerId: this.form.partnerId,
            isEnableSale: this.isEnableSale,
            isEnablePurchase: this.isEnablePurchase,
            searchType: this.searchType,
            queryCode: this.form.details[`${index}`][`${name}`]
          }).then(async response => {
            // 判断是否精准匹配 匹配直接赋值 不匹配弹出商品框
            if (response.total === 1) {
              event.target.blur()
              //使用vue的$set方法更新数组数据,使视图可编辑
              response.rows[0].unitPrice = response.rows[0].salePrice
              response.rows.forEach(item => {
                item.remark = null
              })
              this.$set(this.form.details, index, response.rows[0])
              this.iskey = !this.iskey
              this.form.details = this.form.details.filter(
                item =>
                  (!(JSON.stringify(item) === '{}') &&
                    !(item.goodsId === undefined)) ||
                  !(item.goodsId === undefined)
              )
              if (!bool && this.TypeBill === '107') {
                //需要获取商品当前库存数进入
                getGoodsFinalQty({
                  goodsIds: [response.rows[0].goodsId],
                  storeId: this.form.storeId
                }).then(response => {
                  //账面数
                  this.$set(
                    this.form.details[index],
                    'finalQty',
                    response.data.goodsQtyList[0].finalQty
                  )
                  //初始化基本单位数量
                  this.$set(this.form.details[index], 'basUnitQty', 0)
                })
              }
  
              //字段转换并保留n位小数
              this.dataShowFilter()
            } else {
              this.queryCode = this.form.details[`${index}`][`${name}`]
              this.OpenGoods = true
            }
          })
        } else {
          //没输入直接弹出商品信息框
          event.target.blur()
          this.OpenGoods = true
        }
      },

    // 商品获取库存数
    async goodsGetStock(list,ids) {
      try {
        const stockPromises = list.map(async (item) => {
          if(ids && ids.length > 0 && !ids.includes(item.goodsId)) return item;
          if (item.goodsId && item.storeId) {
            const { data } = await getGoodsStock({
              goodsId: item.goodsId,
              storeId: item.storeId,
              unitId: item.unitId,
              batchNo: item.batchNo,
            });
            item.stockQty = data.finalQty;
            item.stockQtyText = data.finalQtyText; // 这里修正为 finalQtyText
          }
          return item;
        });

        // 使用 Promise.all 并发处理所有请求
        const updatedList = await Promise.all(stockPromises);
        console.log('列表数据B',updatedList)
        return updatedList;
      } catch (err) {
        console.error("获取库存数时出错:", err); // 记录错误信息
        throw err; // 可以选择抛出错误以便外部处理
      }
    },

      async getGoods(data, bool) {
        console.log('列表数据data',data)
        // 在采销模块中，选择商品的备注不需要填入到表单中
        data.forEach(item => {
          item.remark = null
          item.unitSalePrice = item.salePrice
          item.salesAmount = ''
        })
              // 获取商品 库存数
      if (['130102', '130103'].includes(this.billType)) {
        data = await this.goodsGetStock(data);
      }
        if (['130105'].includes(this.billType)) {
          data = data.map((item) => (
            {
              ...item,
              unitPrice:item.priceCasePrice || item.purPrice,
              sourceBillType: item.billType,
              importUnitQty: item.unitQty,
              unitPurPrice: item.purPrice,
              unitSalePrice: item.salePrice,
              unitMoney: ((item.priceCasePrice || item.purPrice) * item.unitQty) || item.unitMoney,
              unitPurMoney: '',
              unitSaleMoney: '',
            }
          ))
        }
  
        // this.tableDataFilterEmpty()
        if (!bool && this.TypeBill === '107') {
          data = this.stockCheckController(data)
        }
        data = data.map((item) => (
          {
            ...item,
            hoverRow: false
          }
        ))
        if (['130104'].includes(this.billType)) {
          data = data.map((item) => (
            {
              ...item,
              hoverRow: false,
              outStoreId: item.storeId
            }
          ))
        }
        if(['130105','130106'].includes(this.billType)){
          data = await this.goodsGetStock(data.map(item => ({...item, storeId: this.billType === '130105' ? this.form.outStoreId : this.form.inStoreId})));
        }
        // 处理点击下标 操作数组
        let findIndex = data[0].findIndex
        if (['130102', '130103', '130104', '130105', '130106', '130107', '130108'].includes(this.billType) && findIndex != undefined && data.length == 1) {
          this.form.details[findIndex] = data[0]
        } else {
          this.form.details.push(...data)
        }
        if (['130102', '130103', '130104', '130105', '130106', '130107', '130108'].includes(this.billType)) {
          this.form.details = this.form.details.filter(item => item.goodsNo != '')
        }
        //追加到数组后面
        this.dataShowFilter()
      },
      //选中的商品-源单 数据
      async getSelectIds(data, bool) {
        console.log('到这里了吗',this.TypeBill,this.billType)
        this.tableDataFilterEmpty()
        if (this.TypeBill === '107') {
          //仓库盘点进入  获取商品库存数
          data = this.stockCheckController(response.rows)[0]
        }
        if (['130106'].includes(this.billType)) {
          const storeArr = this.storeData.filter(item => item.storeId == data[0].outStoreId)
          this.$set(this.form, 'outStoreName', `${storeArr[0].storeName}`)
          this.$set(this.form, 'outStoreId', `${storeArr[0].storeId}`)
          data = data.map((item) => (
            {
              ...item,
              importUnitQty: item.unitQty,
              unitPrice: item.unitPrice,
              // unitMoney: Number(item.unitQty) * Number(item.unitPrice)
            }
          ))
        }
        console.log(data, 'data')
        if (['130105'].includes(this.billType)) {
          data = data.map((item) => (
            {
              ...item,
              sourceBillType: item.billType,
              importUnitQty: item.unitQty,
              unitPurPrice: item.purPrice,
              unitSalePrice: item.salePrice,
              unitPrice: item.priceCasePrice || item.unitPrice,
              unitMoney: ((item.priceCasePrice || item.unitPrice) * item.unitQty) || item.unitMoney,
              unitPurMoney: Number(item.purPrice) * Number(item.unitQty),
              unitSaleMoney: Number(item.salePrice) * Number(item.unitQty),
            }
            // 进货单价 ：purPrice  
            // 单位售价 ： salePrice
            // 调拨单价 ： unitPrice
            // 调拨金额：  unitMoney
          ))
        }

        if(['130105','130106'].includes(this.billType)){
          data = await this.goodsGetStock(data.map(item => ({...item, storeId: this.billType === '130105' ? this.form.outStoreId : this.form.inStoreId})));
        }
  
        // 引入源单回填应收数量
        // data.forEach(item => {
        //   item.importUnitQty = item.unitQty
        // })
        console.log(data, '引入源单数据')
        if (this.billType == '130106') {
          this.form.details = []
          this.form.details.push(...data)
          this.form.billRemark = this.form.details[0].remark
          this.form.billCustomType = data[0].billCustomType
        } else {
          this.form.details.push(...data)
        }
        //追加到数组后面
  
        //字段转换并保留n位小数才进入,这里与dataShowFilter的差异在于重置 showForm的值，暂不处理[23.10.7]  -23.10.8共用
        this.dataShowFilter('source')
  
        // this.addActionRedo(data)
      },
      addActionRedo(data) {
        // 新建情况下 引入源单->回填仓库，采购员，部门字段2023.9.16
        if (!data.length) return
        console.log(data[0], '-引入源单 storeModule')
        const type = this.$route.query.type
        if (type === 'Add') {
          const { storeName, storeId, employeeId, deptId } = data[0]
          const storeType = this.storePostType
          if (storeType === 'inStoreId') {
            this.form.inStoreName = storeName
            this.form.inStoreId = storeId
          } else if (storeType === 'outStoreId') {
            this.form.outStoreName = storeName
            this.form.outStoreId = storeId
          } else if (storeType === 'storeId') {
            this.form.storeName = storeName
            this.form.storeId = storeId
          }
  
          if (employeeId) {
            if (this.BillSaleType) {
              this.form.purEmployeeId = employeeId
            } else {
              this.form.saleEmployeeId = employeeId
            }
          }
          if (deptId) {
            this.form.deptId = deptId
            this.$set(this.form, 'deptId', deptId)
          }
  
          this.$refs.form.clearValidate([
            'inStoreId',
            'outStoreId',
            'storeId',
            'saleEmployeeId',
            'purEmployeeId',
            'purDeptId'
          ])
        }
      },
      //根据单据id 获取单据详情
      getBillDetail() {
        this.loading = true
        // 特殊处理 仓库调入 使用这个接口查询信息
        if (this.billType === '130106' && this.$route.query.type === 'Add') {
          getSourceBill('/api/system/source/bill/getSourceBillOrder', {
            billType: '130106',
            billId: this.billId,
          }).then(response => {
            response.rows = response.rows.map(x => ({
              ...x,
              sourceBillType: x.billType
            }))
            this.form.details = response.rows
            this.form.inStoreId = response.rows[0].inStoreId
            this.form.outStoreId = response.rows[0].outStoreId
            const inStoreIndex = this.storeData.findIndex(item => item.storeId == this.form.inStoreId)
            const outStoreIndex = this.storeData.findIndex(item => item.storeId == this.form.outStoreId)
            this.form.inStoreName = this.form.inStoreId ? this.storeData[inStoreIndex].storeName : ''
            this.form.outStoreName = this.form.outStoreId ? this.storeData[outStoreIndex].storeName : ''
            if (this.form.billStatus === '2') {
              this.disabled = true
              this.auditOrder = true
            } else if (this.form.billStatus === '4') {
              this.disabled = true
              this.stopOrder = true
              this.showAudit = true
            } else if (this.form.billStatus === '3') {
              this.disabled = true
              this.showAudit = true
              this.deleteOrder = true
            } else {
              this.disabled = false
              this.stopOrder = false
              this.showAudit = false
              this.auditOrder = false
              this.deleteOrder = false
            }
            this.loading = false
            // this.$emit('getBillsIds', this.customReflect([...response.rows]))
          })
        } else {
          getDetailBill(this.billId, this.urlDetail).then(async response => {
            let temp = 1
            if (['130101', '140309', '170309', '170409'].includes(this.billType)) {
              this.form = { ...response.data, details: [{}] }
              this.setTableData(response.data.details)
            } else {
              this.form = response.data
              if (this.form.fileItems?.length > 0) {
                this.form.fileItems = this.form.fileItems.map((item) => (
                  {
                    ...item,
                    name: item.fileName,
                    url: item.accessUrl,
                  }
                ))
              }
            }
            this.deptIdProcess(this.form)
            console.log('csp - 采购明细 =/ ', this.form)
            this.detailDataFormat(this.form.details)
  
            this.form.inStoreNameCopy = this.form.outStoreName
            this.form.inStoreIdCopy = this.form.outStoreId
  
            this.$nextTick(() => {
              this.billUpdateProcess()
            })
            if (this.form.fileItems) {
              this.form.fileItems.forEach(i => {
                this.fileList.push({
                  name: i.fileName,
                  url: i.accessUrl,
                  domain: i.domain,
                  filePath: i.filePath,
                  fileSize: i.fileSize,
                  uid: new Date().getTime() + temp++
                })
              })
            }
            if (this.form.billStatus === '2') {
              this.disabled = true
              this.auditOrder = true
            } else if (this.form.billStatus === '4') {
              this.disabled = true
              this.stopOrder = true
              this.showAudit = true
            } else if (this.form.billStatus === '3') {
              this.disabled = true
              this.showAudit = true
              this.deleteOrder = true
            } else {
              this.disabled = false
              this.stopOrder = false
              this.showAudit = false
              this.auditOrder = false
              this.deleteOrder = false
            }
            // 仓库调出单明细 
            if (this.form.billType === '130105') {
              this.form.details = this.form.details.map((item) => (
                {
                  ...item,
                  unitPurPrice: item.unitPurPrice,
                  unitSalePrice: item.unitSalePrice,
                  unitPrice: item.unitPrice,
                  unitMoney: item.unitMoney,
                  // unitPurMoney: Number(item.unitPurPrice) * Number(item.unitQty),
                  // unitSaleMoney: Number(item.unitSalePrice) * Number(item.unitQty),
                  unitPurMoney: fcount([item.unitPurPrice, item.unitQty], "*"),
                  unitSaleMoney: fcount([item.unitSalePrice, item.unitQty], "*"),
                }
              ))
            } else if (this.form.billType === '130102' && this.form.billStatus === '0') {
              //仓库调整单
              for (let index = 0; index < this.form.details.length; index++) {
                if (this.form.details[index].sourceBillDetailId && this.form.details[index].sourceBillDetailId != null && this.form.details[index].sourceBillDetailId != '') {
                  this.disabled = true;
                  this.disabledRemark = false;
                  this.disabledBtn = false;
                }
              }
            }
            // 增加仓库逻辑
            if (['130103'].includes(this.billType)) {
              this.form.details = this.form.details.map((item) => (
                {
                  ...item,
                  storeId: item.outStoreId
                }
              ))
            }
            if (['130102'].includes(this.billType)) {
              this.form.details = this.form.details.map((item) => (
                {
                  ...item,
                  storeId: this.form.billPostType == 1 ? item.inStoreId : item.outStoreId
                }
              ))
            }
            console.log('his.form.checkGoodsSourceType', this.form.checkGoodsSourceType)
            if (['130101', '140309', '170309', '170409'].includes(this.billType)) {
              this.optionGoods = this.$select({
                key: 'listGoods',
                option: {
                  option: {
                    noEcho: true,
                    remote: ['130101', '140309', '170309', '170409'].includes(this.billType) ? httpStoreListCheckGoods : undefined,
                    remoteBody: ['130101', '140309', '170309', '170409'].includes(this.billType) ? {
                      storeId: this.form.storeId,
                      isLockStore: this.form.isLockStore,
                      isIncludeZeroStock: this.form.isIncludeZeroStock,
                      goodsSource: this.form.checkGoodsSourceType,
                    } : { excludeGoodsTypes: -1 },
                    change: (propValue, row) => {
                      console.log(propValue, 'row,changechangechangechangechange')
                      console.log(row, 'row,changechangechangechangechange')
                      this.handleEvent('getSelectGoods', [row])
                    },
                    tableChange: (propValue, row) => {
                      console.log('propValue,', propValue)
                      console.log('row,', row)
                      this.handleEvent('getSelectGoods', row.check)
                    },
                    buttons: [
                      {
                        type: 'more',
                        option: {
                          title: '选择商品',
                          width: 1250,
                          type: 'TreeAndTable',
                          formData: this.$dialog({
                            key: 'goods',
                            option: {
                              // treeApi: undefined,
                              queryParams: ['130101', '140309', '170309', '170409'].includes(this.billType) ? {
                                storeId: this.form.storeId,
                                isLockStore: this.form.isLockStore,
                                isIncludeZeroStock: this.form.isIncludeZeroStock,
                                goodsSource: this.form.checkGoodsSourceType,
                              } : { excludeGoodsTypes: -1 },
                              table: {
                                ...this.$dialog({ key: 'goods' }).table,
                                list: ['130101', '140309', '170309', '170409'].includes(this.billType) ? httpStoreListCheckGoods : undefined,
                              }
                            }
                          })
                        }
                      }
                    ]
                  }
                }
              }).option
            }
            this.loading = false
          })
        }
      },
      detailDataFormat(data) {
        for (let i = 0; i < data.length; i++) {
          let obj = { ...data[i] }
          for (let key in obj) {
            if (
              key === 'unitPrice' ||
              key === 'unitTaxMoney' ||
              key === 'attachMoney' ||
              key === 'taxRate'
            ) {
              this.dataFormat(obj, key, i)
            }
          }
        }
      },
      storeSelectBills(val) {
        console.log('val,', val)
        console.log('val2,', this.shopType)
        //调出
        if (this.TypeBill === '130105') {
          if (!this.form.inStoreId) {
            this.$message.error('请先选择调入仓库')
            return
          }
        } else if (this.TypeBill === '130106') {
          //调入
          if (!this.form.inStoreId) {
            this.$message.error('请先选择调入仓库')
            return
          }
        }
  
        let title = ''
        if (this.TypeBill === '130105') {
          title = '引入源单'
        }
        this.title = title
        this.NoFields = [
          { label: '单据编号', name: 'billNo' },
          { label: '单据状态', name: 'billStatus' }
        ]
        this.tableColumnFields = [
          { label: '商品编码', name: 'goodsNo' },
          { label: '商品名称', name: 'goodsName' },
          { label: '条码', name: 'barcode' },
          { label: '规格', name: 'goodsSpec' },
          { label: '单位', name: 'unitName' },
          { label: '数量', name: 'unitQty' },
          { label: '单价', name: 'unitPrice' },
          { label: '金额', name: 'unitMoney' },
          { label: '备注', name: 'remark' }
        ]
        this.loadingDia = true
  
        console.log('到这里了嘛源单',this.form)
        let query = {
          billType: this.billType,
          outStoreId: this.form.outStoreId,
          inStoreId: this.form.inStoreId,
          sourceBillType: 140301,
          priceCaseId: this.form.priceCaseId,
          isUseShopTransferPrice:true,
          ...val
        }
        if (this.TypeBill === '130106') {
          this.tableColumnFields = [
            { label: '单据日期', name: 'billDate' },
            { label: '单据编号', name: 'billNo' },
            // { label: '调入仓库ID', name: 'inStoreId' },
            { label: '调入仓库名称', name: 'inStoreName' },
            // { label: '调出仓库ID', name: 'outStoreId' },
            { label: '调出仓库名称', name: 'outStoreName' },
            { label: '单据金额', name: 'billMoney' },
            { label: '单据数量', name: 'billQty' },
            // { label: '单据类型', name: 'billType' },
            { label: '单据类型', name: 'billTypeName' },
            { label: '已引入单据号', name: 'finishBillNo' },
            { label: '已引入单据类型', name: 'finishBillTypeName' },
            { label: '已引入单据状态', name: 'finishBillStatusName' },
            { label: '备注', name: 'remark' }
          ]
          let urlType = this.shopType == 1 ? listSourceBill : listSourceBillFranchise
          urlType(query).then(response => {
            // 源单所有数据
            this.billList = [...response.rows]
            // 全选时需要的数据
            this.allBillList = [...response.rows]
            this.billTotal = response.total
            this.loadingDia = false
            this.openBillDia = true
          })
        } else {
          // 添加供应商id
          getSourceBill(this.urlSourceBill, query).then(response => {
            // 源单所有数据
            this.billList = [...response.rows]
            // 全选时需要的数据
            this.allBillList = [...response.rows]
            this.billTotal = response.total
            this.loadingDia = false
            this.openBillDia = true
          })
        }
      }
    }
  }
  
  export { storeDetailLayout }
  