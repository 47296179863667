import Cookies from 'js-cookie'
import { v4 } from 'uuid';
import { isObject, deepCopy } from '@/utils'
import md5 from '@/utils/md5'
import { isArray } from 'lodash'
export async function encryptSignature({ url, params = {}, method }) {
  // 检查URL是否包含不需要加密的关键字
  const excludeKeywords = ['Export', 'export', 'Import', 'import','open','Open','/api/system/log/release'];
  if (excludeKeywords.some(keyword => url.toLowerCase().includes(keyword))) {
    // 如果URL包含不需要加密的关键字，则直接返回原始URL和参数
    return { curUrl: url, newParams: params };
  }
  params = JSON.parse(JSON.stringify(params, (key, value) => {
    // 返回undefined将会忽略该属性
    return (value === null||value === undefined||value === '') ? undefined : value;
  }))
  let tenantId = Cookies.get('keyId') || ''
  // console.log('签名商户id', tenantId);
  const timestamp = new Date().getTime(); //时间戳
  const nonce = v4() // 随机32位字符串
  // 密钥生成：'SYAafdf87tA%+2hB2py9332@fdmz' + 商户ID  , 再进行MD5
  let key = 'SYAafdf87tA%+2hB2py9332@fdmz' + tenantId//密钥
  // console.log('签名商户key', key)
  // console.log('签名商户url', url)
  key = md5(key) //
  let sign = ""; //签名字符串
  let newParams //入参
  let curUrl = url //请求url
  //处理参数
  method = method.toLowerCase();
  if (['get', 'delete'].includes(method) && url.includes('?')) {
    const { newUrl, queryParams } = getQueryParams(url)
    curUrl = newUrl
    const { resultObj } = await tansParams({ ...queryParams, timestamp, nonce })
    newParams = resultObj
  } else if (method === 'get') {
    const { resultObj } = await tansParams({ ...params, timestamp, nonce })
    newParams = resultObj
  } else {
    if (isArray(params)) {
      params = params.map(obj => {
        if (typeof obj === 'object') {
          return Object.keys(obj).reduce((acc, key) => {
            const value = obj[key];
            if (value !== undefined && value !== null && value !== '') {
              acc[key] = value;
            }
            return acc;
          }, {});
        } else {
          return obj;
        }
      });
    }
    newParams = isArray(params) ? { arr: JSON.stringify(params), timestamp, nonce } : { ...params, timestamp, nonce }
  }
  if (newParams && isObject(newParams)) {
    //对象key按字母排序 拼接字符串成扫呗签名
    let newkey = Object.keys(newParams).sort()
    for (var i = 0; i < newkey.length; i++) {
      let type = typeof newParams[newkey[i]]
      //遍历newkey数组 空值过滤
      if (![undefined, null, ''].includes(newParams[newkey[i]])) {
        sign += `${newkey[i]}=${type === 'object' ? JSON.stringify(filterData(newParams[newkey[i]])) : newParams[newkey[i]]}&`;
      }
    }
  }
  sign += `key=${key}`;
  // console.log('签名key', key)
  // console.log('签名原数据sign', sign)
  sign = md5(sign); //md5加密签名
  // console.log('地址请求方式', method, url)
  if (method === 'get' || (method === 'delete' && url.includes('?'))) {
    const { result } = await tansParams({ ...newParams, sign })
    curUrl = curUrl + '?' + result
    curUrl = curUrl.slice(0, -1)
    newParams = {}
    // console.log(curUrl, '地址')
    return {
      curUrl,
      newParams
    }
  } else if (method === 'delete') {
    const { result } = await tansParams({ nonce, timestamp, sign })
    curUrl = curUrl + '?' + result
    return {
      curUrl,
      newParams: params
    }
  } else {
    return {
      curUrl,
      newParams: { ...newParams, nonce, timestamp, sign }
    }
  }
}


/**
 * 参数处理
 * @param {*} params  参数
 */
export function tansParams(params) {
  if (!params) return ''
  let result = ''
  let resultObj = {}
  for (const propName of Object.keys(params)) {
    const value = params[propName]
    var part = encodeURIComponent(propName) + '='
    if (value !== null && value !== '' && typeof value !== 'undefined') {
      if (typeof value === 'object') {
        for (const key of Object.keys(value)) {
          if (
            value[key] !== null &&
            value[key] !== '' &&
            typeof value[key] !== 'undefined'
          ) {
            let params = propName + '[' + key + ']'
            var subPart = encodeURIComponent(params) + '='
            result += subPart + encodeURIComponent(value[key]) + '&'
            resultObj[params] = value[key]
          }
        }
      } else {
        result += part + encodeURIComponent(value) + '&'
        resultObj[encodeURIComponent(propName)] = value
      }
    }
  }
  return { result, resultObj }
}

// 过滤空值
export function filterData(obj) {
  if (isArray(obj) || isObject(obj)) {
    const cloneObj = new obj.constructor()
    // eslint-disable-next-line no-restricted-syntax
    for (const key in obj) {
      const item = filterData(obj[key])

      if (![undefined, null].includes(item)) {
        if (isArray(obj)) {
          cloneObj.push(item)
        } else {
          cloneObj[key] = item
        }
      }
    }
    return cloneObj
  }
  return obj
}

//截取url问号后面的参数 拼接成对象
export function getQueryParams(url) {

  const queryString = url.split('?')[1]; // 获取URL中的查询字符串
  const queryParams = {};

  queryString.split('&').forEach(pair => {
    const [key, value] = pair.split('=');
    queryParams[decodeURIComponent(key)] = decodeURIComponent(value || '');
  });

  let newUrl = url.replace(/\?.*$/, '')

  return { newUrl, queryParams };
}

