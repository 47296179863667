import store from '@/store/index.js'
import { getToken } from '@/utils/auth'

let heartbeatInterval

// 初始化 WebSocket
function initWebSocket() {
  // 如果未登录，则跳过初始化
  if (!getToken()) return
  // console.log(
  //   '开始初始化 WebSocket',
  //   store.state.webSocket,
  //   store.state.webSocket.socketUrl
  // )
  // 未连接的情况下才尝试链接
  if (!store.state.webSocket.lockReconnect) {
    // console.log('链接中......')
    store.state.webSocket.lockReconnect = true // 锁定重连状态

    // 建立连接
    store.state.webSocket.socketTask = new WebSocket(
      `${store.state.webSocket.socketUrl}?token=${getToken()}`
    )
    // console.log(store.state.webSocket.socketUrl, 'webSocket链接地址')

    // 连接成功
    store.state.webSocket.socketTask.onopen = websocketonopen

    // 连接错误
    store.state.webSocket.socketTask.onerror = websocketonerror

    // 连接关闭
    store.state.webSocket.socketTask.onclose = websocketcloseInt

    // 接收信息
    store.state.webSocket.socketTask.onmessage = websocketonmessage
  } else {
    clearTimeout(store.state.webSocket.timeoutnum)
  }
}

// 重新连接
function reconnect() {
  if (store.state.webSocket.lockReconnect) {
    return
  }
  store.state.webSocket.lockReconnect = false
  // console.log('开始重连...')

  setTimeout(() => {
    store.state.webSocket.reconnectNum++
    // 新连接
    initWebSocket()
  }, 5000)
}

// 重置心跳
function reset() {
  //清除时间
  clearInterval(heartbeatInterval)
  //重启心跳
  if (store.state.webSocket.isQuit) {
    // console.log('退出了登录,不需要重连!')
    return
  }
  start()
}

// 开始心跳
function start() {
  // console.log('webSocket  准备心跳1 = ')
  clearInterval(heartbeatInterval)

  heartbeatInterval = setInterval(() => {
    if (store.state.webSocket.isQuit == false) {
      if (store.state.webSocket.socketTask.readyState === WebSocket.OPEN) {
        store.state.webSocket.socketTask.send(
          JSON.stringify({ code: 0, content: 'ping' })
        )
      } else {
        reconnect()
      }
      //console.log('开始心跳')
    } else {
      clearInterval(heartbeatInterval)
      // console.log('结束心跳')
    }
  }, store.state.webSocket.timeout * 1000) // 将心跳时间转换为毫秒
}

// 连接成功事件
function websocketonopen() {
  // console.log('连接成功事件')
  start()
}

// 连接错误事件
function websocketonerror(e) {
  // console.log('连接错误事件')
  store.state.webSocket.lockReconnect = false
  reconnect()
}

// 连接关闭事件
function websocketcloseInt(e) {
  // console.log('连接关闭事件')
  store.state.webSocket.lockReconnect = false
  reconnect()
}

// 向服务器发送消息
function websocketsend(msg) {
  if (store.state.webSocket.socketTask.readyState === WebSocket.OPEN) {
    store.state.webSocket.socketTask.send(msg)
  } else {
    store.state.webSocket.socketTask.close()
    initWebSocket()
  }
}

// 接收数据
function websocketonmessage(e) {
  let resData = JSON.parse(e.data)
  //console.log('收到服务器信息', resData);
  if (resData.code !== 0 || resData.content !== 'ping') {
    // 收到服务器信息，心跳重置
    reset()
  }
}

// 监听storage变化以进行声音通知
function eventStorage() {
  var originalSetItem = localStorage.setItem
  localStorage.setItem = function (key, newValue) {
    var setItemEvent = new Event('setItemEvent')
    setItemEvent.newValue = newValue
    window.dispatchEvent(setItemEvent)
    originalSetItem.apply(this, arguments)
  }
}

// 关闭 WebSocket 连接
function webSocketClose() {
  // console.log('关闭webSocket连接', store.state.webSocket)
  store.state.webSocket.socketTask.close()
  store.state.webSocket.isQuit = true
}

// 初始化 WebSocket
initWebSocket()

export { initWebSocket, webSocketClose }
