import { fcount } from "@/utils";

//基本单位售价
export function unitSalePriceChange(value, row, billType) {
  let billTypeNum = this.billType || billType
  billTypeNum = billTypeNum ? Number(billTypeNum) : null;
  row.units.forEach(v => {
    if (v.unitId === value) {
      switch (billTypeNum) {
        case 140310:
        case 140311:
          {
            this.$set(row, 'unitPurPrice', v.purPrice)
            const unitPurMoney = row.unitQty * v.purPrice
            this.$set(row, 'unitPurMoney', unitPurMoney)
            this.$set(row, 'unitSalePrice', v.salePrice)
            const unitSaleMoney = this.$syInput(3, fcount([row.unitQty, v.salePrice], '*'))
            this.$set(row, 'unitSaleMoney', unitSaleMoney)
            this.$set(row, 'barcode', v.barcode)
            this.$set(row, 'goodsSpec', v.goodsSpec)
            break;
          }
        case 140314:
          {
            this.$set(row, 'unitPrice', v.salePrice)
            this.$set(row, 'unitOldPrice', v.salePrice)
            const unitMoney = this.$syInput(3, fcount([row.unitQty, row.unitPrice], '*'))
            this.$set(row, 'unitMoney', unitMoney)
            this.$set(row, 'unitOldMoney', unitMoney)
            this.$set(row, 'barcode', v.barcode)
            this.$set(row, 'goodsSpec', v.goodsSpec)
            break;
          }
        default:
          const unitQty = row.unitQty ? Number(row.unitQty) : row.unitQty
          this.$set(row, 'unitPrice', v.salePrice)
          const unitMoney = this.$syInput(3, fcount([unitQty, row.unitPrice], '*'))
          this.$set(row, 'unitMoney', unitMoney)
          this.$set(row, 'barcode', v.barcode)
          this.$set(row, 'goodsSpec', v.goodsSpec)
          break;
      }
    }
  })
}
//基本单位进价
export function unitPriceChange(value, row) {
  row.units.forEach(v => {
    if (v.unitId === value) {
      this.$set(row, 'unitPrice', v.unitPrice)
      const unitMoney = row.unitQty * row.unitPrice
      this.$set(row, 'unitMoney', unitMoney)
      this.$set(row, 'barcode', v.barcode)
      this.$set(row, 'goodsSpec', v.goodsSpec)
    }
  })
}

//仓库基本单位售价
export function checkUnitSalePriceChange(value, row) {
  row.units.forEach(v => {
    if (v.unitId === value) {
      this.$set(row, 'unitSalePrice', v.salePrice)
      const unitMoney = row.unitQty * row.unitSalePrice
      this.$set(row, 'unitMoney', unitMoney)
      this.$set(row, 'barcode', v.barcode)
      this.$set(row, 'goodsSpec', v.goodsSpec)
    }
  })
}
